import { containsAll } from "components/GlobalHelpers";

export const SalesLocationsFilters = () => {
  const $country_filter = jQuery(".sales-locations-filters__select--country");
  const $state_filter = jQuery(".sales-locations-filters__select--state");
  const $grids = jQuery(".sales-locations-grid");

  jQuery(".sales-locations-filters").each(function() {
    let $block = jQuery(this),
      $selects = $block.find(".sales-locations-filters__select");
    $selects.each(function() {
      let $dropdown_container = jQuery(
          '<div class="select2-container__dropdown-wrap"></div>'
        ),
        filter_type = "";

      if (jQuery(this).hasClass("sales-locations-filters__select--state")) {
        filter_type = "states";
      } else if (
        jQuery(this).hasClass("sales-locations-filters__select--country")
      ) {
        filter_type = "countries";
      } else {
        return;
      }

      // append dropdown container and use so we can target the dropdown with styling
      jQuery(this).after($dropdown_container);

      import(/* webpackChunkName: "select2" */ "select2")
        .then(() => {
          jQuery(this).select2({
            // minimumResultsForSearch: Infinity,
            theme: "select2-styles",
            dropdownPosition: "below",
            dropdownParent: $dropdown_container
          });

          jQuery(this).on("change", function() {
            let $filter_value = parseInt(jQuery(this).select2("val"));
            $grids.each(function() {
              jQuery(this).trigger("filter-grid", [$filter_value, filter_type]);
            });
          });
        })
        .catch(err => {
          console.error(err);
        });
    });
  });

  $grids.on("filter-grid", function(e, filter_value, filter_type) {
    let $this = jQuery(this),
      $grid_items = $this.find(".sales-locations-grid__item"),
      found_items = filter_items($grid_items, filter_value, filter_type);
    if (found_items == 0) {
      jQuery(this)
        .find(".sales-locations-grid__no-results")
        .show();
    } else {
      jQuery(this)
        .find(".sales-locations-grid__no-results")
        .hide();
    }
  });

  /**
   * filter
   * @param  {jquery object} the items
   * @param  {string} $filter_value takes a country/state value
   * @param  {string} $filter_type  either 'country' or 'state'
   * @return {boolean}               returns true/false on success/fail
   */
  let filter_items = function($grid_items, filter_value, filter_type) {
    let $number_of_items = $grid_items.length,
      $non_matching_items;

    // show all items if all countries is selected
    if (filter_value == -1 && filter_type == "countries") {
      $grid_items.show();
      $non_matching_items = [];
    }
    // filter for countries
    else if (filter_type == "countries") {
      $non_matching_items = $grid_items.filter(function() {
        let countries = JSON.parse(jQuery(this).attr("data-" + filter_type));
        if (!countries.includes(filter_value)) {
          return true;
        }
      });
      $grid_items.not($non_matching_items).show();
      $non_matching_items.hide();
    }
    // filter for states
    else if (filter_type == "states") {
      // should always be 'United States'
      let current_country = parseInt($country_filter.select2("val"));

      $non_matching_items = $grid_items.filter(function() {
        let countries = JSON.parse(jQuery(this).attr("data-" + filter_type)),
          states = JSON.parse(jQuery(this).attr("data-" + filter_type));

        if (
          !containsAll(current_country, countries) &&
          !containsAll(filter_value, states)
        ) {
          return true;
        }

        if (
          !countries.includes(current_country) &&
          !states.includes(filter_value)
        ) {
          return true;
        }
      });
      $grid_items.not($non_matching_items).show();
      $non_matching_items.hide();
    } else {
      console.log("no matching filter rules");
      return;
    }

    // show states select box if united states or state filter_type
    if (filter_value == "1714" || filter_type == "states") {
      $state_filter.removeAttr("disabled");
    } else {
      $state_filter.val("-1").trigger("change.select2");
      $state_filter.prop("disabled", true);
    }

    return $number_of_items - $non_matching_items.length;
  };
};
